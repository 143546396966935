import React from 'react'
import { Link } from 'gatsby'
import MothButtons from '../../components/MothButtons'
import SEO from '../../components/seo'
import "../layout.css"

export default function Writing() {
    return (
        <>
        <SEO title="Writing" />
        <div className="writing-content-wrap">
            <MothButtons size="small" order={[
                    {to: "/home", label:  "Home"},
                    {to: "/portfolio", label:  "Portfolio"}
                    ]}/>
            <div className="writeballs">
                <Link to="columns">
                    <div id="column-ball" />
                </Link>
                <Link to="articles">
                    <div id="article-ball" />
                </Link>
                <Link to="poems">
                    <div id="poem-ball" />
                </Link>
            </div>
            <div className="preloaded-gd-images" id="columnBallRo" />
            <div className="preloaded-gd-images" id="articleBallRo" />
            <div className="preloaded-gd-images" id="poemBallRo" />
        </div>
        </>
    )
}